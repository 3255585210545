import React from "react";
import WhatsAppButton from "./WhatsAppButton";

import "../css/index.scss";

import mainLogo from "../img/main-logo.svg";
import mainHeaderImg from "../img/main-header-img.png";
import AboutButton from "./AboutButton";

const Header = () => {
  return (
    <header className="main-header">
      <div className="container">
        <section>
          <div className="text">
            <img
              className="main-logo"
              src={mainLogo}
              alt="Logotipo BigFichas"
            />
            <h2>
              Compre e venda suas fichas de <b>poker online.</b>
            </h2>
            <span className="buttons">
              <AboutButton title="Quem somos" />
              <WhatsAppButton title="Fale conosco" />
            </span>
          </div>
          <div>
            <img
              className="poker-chips"
              src={mainHeaderImg}
              alt="Imagem de fichas de poker empilhadas."
            />
          </div>
        </section>
      </div>
    </header>
  );
};

export default Header;
