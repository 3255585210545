import React from "react";

import { BsInstagram } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs";

import "../css/index.scss";

import mainLogo from "../img/main-logo.svg";

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className="main-footer">
      <section className="contact">
        <div className="container">
          <h2>
            Siga-nos nas redes sociais, tire suas dúvidas e fique por dentro das{" "}
            <b>novidades e promoções!</b>
          </h2>
          <ul className="buttons">
            <li>
              <a
                href="https://instagram.com/bigfichas"
                target="_blank"
                rel="noreferrer"
              >
                <BsInstagram />
              </a>
            </li>
            <li>
              <a
                href="https://wa.me/5583998727772"
                target="_blank"
                rel="noreferrer"
              >
                <BsWhatsapp />
              </a>
            </li>
          </ul>
        </div>
      </section>
      <section className="copyright">
        <div className="container">
          <img src={mainLogo} alt="Logotipo BigFichas" />
          <p>© {year}</p>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
