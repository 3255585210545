import React from "react";

import "../css/index.scss";

import platform1 from "../img/platform-1.png";
import platform3 from "../img/platform-3.png";
import platform4 from "../img/platform-4.png";

const Content = () => {
  return (
    <section className="main-content">
      <div className="container">
        <h2>
          <b>Praticidade e segurança</b> para jogar nas melhores plataformas de
          poker.
        </h2>
        <div className="content">
          <div className="box">
            <img src={platform1} alt="Logotipo Suprema Poker" />
            <h3>ID - 25784</h3>
          </div>
          <div className="box">
            <img src={platform3} alt="Logotipo PPPoker" />
            <h3>
              ID Liga Evolution - 641476
              <br />
              ID Liga 388 - 3845565
            </h3>
          </div>
          <div className="box">
            <img src={platform4} alt="Logotipo PixPoker" />
            <h3>
              ID Clube - 10012
              <br />
              ID Afiliado - 100849
            </h3>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Content;
