import React from "react";
import { BsWhatsapp } from "react-icons/bs";

const WhatsAppButton = ({ title }) => {
  const whatsappNumber = `https://wa.me/5583998727772`;
  return (
    <a href={whatsappNumber} target="_blank" rel="noreferrer">
      <button className="whatsapp-button" type="button">
        <BsWhatsapp />
        <div>
          <h3>{title}</h3>
        </div>
      </button>
    </a>
  );
};

export default WhatsAppButton;
