import { useState } from "react";
import React from "react";
import Modal from "react-modal";
import { BsFillPeopleFill } from "react-icons/bs";

const AboutButton = ({ title }) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div>
      <button className="about-button" type="button" onClick={openModal}>
        <BsFillPeopleFill />
        <div>
          <h3>{title}</h3>
        </div>
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        overlayClassName="modal-overlay"
        className="modal-content"
      >
        <section className="modal">
          <h2>Bem-vindo ao BigFichas!</h2>
          <p>
            Desde 2016, estamos orgulhosos de ser um dos principais fornecedores
            de fichas de poker online para jogadores em todo o país.
          </p>
          <p>
            Somos uma equipe apaixonada pelo poker, e sabemos como é importante
            ter segurança na hora de comprar suas fichas online. Quer você seja
            um jogador iniciante ou experiente, oferecemos uma ampla variedade
            de plataformas de poker para todos os gostos. Temos orgulho em
            oferecer uma experiência de compra simples, rápida e segura.
          </p>
          <p>
            Nosso compromisso em manter um atendimento humanizado e prestativo
            ao cliente sempre foi nosso diferencial. Nossa equipe está sempre
            disponível para ajudá-lo com qualquer dúvida ou preocupação que você
            possa ter. Estamos empenhados em oferecer um serviço excepcional e
            garantir que nossos clientes estejam sempre satisfeitos com suas
            compras.
          </p>
          <p>
            Agradecemos por escolher o BigFichas! Estamos ansiosos para ajudá-lo
            a ter a melhor experiência possível no poker online.
          </p>
          <p>
            <b>Vem ser Big!</b>
          </p>
        </section>
      </Modal>
    </div>
  );
};

export default AboutButton;
